<template>
  <div class="faq-block">
    <div class="page-title"><h2>{{ $lang.app.faq }}</h2></div>
    <div class="faq-info">
      <div class="faq-left">
        <div class="faq-left-container">
          <h4 class="hint-title">{{ $lang.app.regulatory_legal_framework_hint }}</h4>
          <div class="docs-items">
            <div v-for="lawItem in law" :key="lawItem.id" class="docs-item">
              <a href @click.prevent="openFile(lawItem)">
                <i class="bi-book"></i>
                {{ $lang.$translate({ru: lawItem.titleRu, kg: lawItem.titleKg}) }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="faq-right">

        <div>
          <div class="links__list">
            <div class="link__item-wrap">
              <div class="link__item">
                <router-link to="/faq?type=PRESCHOOL" class="link">
                  <h4 class="link__item-title">{{ this.$lang.app.preschool }}</h4>
                  <p class="link__item-descr">{{ this.$lang.app.preschool_faq_description }}</p>
                </router-link>
              </div>
            </div>
            <div class="link__item-wrap">
              <div class="link__item">
                <router-link to="/faq?type=SCHOOL" class="link">
                  <h4 class="link__item-title">{{ this.$lang.app.school }}</h4>
                  <p class="link__item-descr">{{ this.$lang.app.school_faq_description }}</p>
                </router-link>
              </div>
            </div>
          </div>

          <div class="sign-in-wrapper">
            <router-link to="/auth/login-parent" class="sign-in button-blue">
              {{ $lang.app.login_parent_account }}
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import fileUploadMixin from "../mixins/fileUploadMixin";

export default {
  name: 'Law',
  mixins: [fileUploadMixin],
  data() {
    return {
      law: [],
    }
  },
  mounted() {
    this.fetchLaw()
  },
  methods: {
    fetchLaw() {
      this.$axios.get(`/legal-act/list`).then(({data}) => {
        this.law = data
      })
    },
    openFile(lawItem) {
      this.downloadFile(lawItem.filename).then(data => {
        let extension = lawItem.filename.split('.').pop().toLowerCase();
        if(['png', 'jpg', 'jpeg'].includes(extension)) {
          let file = URL.createObjectURL(new Blob([data],{type: `image/${extension}`}));
          window.open(file, '_blank');
        }
        if(extension === 'pdf') {
          let file = URL.createObjectURL(new Blob([data],{type: 'application/pdf'}));
          window.open(file, '_blank');
        }
      })
    },
  }
}
</script>
<style>
@import '../assets/styles/title.css';

.docs-items .docs-item {
  margin-bottom: 30px;
}
.docs-items .docs-item a {
  color: #1f1f1f;
  text-decoration: none;
  vertical-align: middle;
  font-weight: bold;
  position: relative;
  padding-left: 65px;
  display: inline-block;
}
.docs-items .docs-item a i {
  color: #969694;
  font-size: 36px;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.faq-info {
  display: flex;
}
.faq-info .faq-left {
  width: 66.6666%;
  padding: 50px 8.3333% 70px;
}
.hint-title {
  padding: 20px;
  margin: 0 0 30px;
  background: rgba(76,154,199,0.15);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}
.faq-info .faq-right {
  background: #e8e8e8;
  width: 33.3333%;
  display: flex;
  justify-content: center;
  padding: 50px 30px;
}
.faq-info .faq-right .write-us-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.faq-info .faq-right h3 {
  color: #1f1f1f;
  text-transform: uppercase;
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  margin: 0 0 40px;
}
.faq-info .faq-right p {
  margin: 0 0 40px;
  color: #1f1f1f;
  font-size: 16px;
  text-align: left;
  width: 100%;
}
.faq-info .faq-right form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.faq-info .faq-right form .write-us-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.faq-info .faq-right form .write-us-inputs input {
  width: 47%;
  height: 55px;
  line-height: 55px;
  border-radius: 5px;
  border: none;
  padding: 0 15px;
  outline: none;
}
.faq-info .faq-right form .select-block {
  position: relative;
  margin-bottom: 20px;
}
.faq-info .faq-right form .select-block i {
  position: absolute;
  font-size: 18px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}
.faq-info .faq-right form .select-block select {
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 55px;
  line-height: 55px;
  border-radius: 5px;
  border: none;
  padding: 0 15px;
  color: #888;
}
.faq-info .faq-right form .select-block option {
  color: #888;
}
.faq-info .faq-right form textarea {
  line-height: 55px;
  border-radius: 5px;
  border: none;
  padding: 0 15px;
  max-width: 100%;
  height: 145px;
  margin-bottom: 20px;
}
.faq-info .faq-right form .faq-controls {
  width: 100%;
  display: flex;
  justify-content: center;
}
.faq-info .faq-right form button {
  outline: none;
  width: 100%;
  max-width: 220px;
  height: 55px;
  line-height: 55px;
  border: none;
  background: linear-gradient(to bottom, #63b8d8 0%, #357cb6 100%);
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 0;
  transition: 0.5s;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  text-decoration: none;
}
.faq-info .faq-right form button:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}

.links__list {
  margin-bottom: 40px;
}
.link__item-wrap {
  padding: 0 15px;
  margin-bottom: 30px;
}
.link__item {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 25px 30px;
  border-radius: 4px;
  transition: box-shadow .3s cubic-bezier(.25,.8,.25,1),background .3s ease-in-out;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  background: white;
}
.link__item:hover {
  box-shadow: 0 7px 20px rgb(0 0 0 / 12%), 0 3px 10px rgb(0 0 0 / 9%);
}
.link {
  position: relative;
  line-height: 1.5em;
  transition: color .2s ease-in-out;
  text-decoration: none;
}
.link__item-title {
  margin-bottom: 10px;
  color: #367db7;
  font-size: 20px;
  transition: color .2s ease-in-out;
  font-weight: 600;
}
.link__item-descr {
  margin: 10px 0 0 !important;
}
.sign-in-wrapper {
  text-align: center;
  padding: 0 15px;
}
.sign-in {
  font-weight: bold;
  color: #fff;
  display: inline-block;
  width: 100%;
  max-width: 320px;
  height: 55px;
  line-height: 55px;
  overflow: hidden;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  transition: 0.5s;
  margin-bottom: 20px;
}
.sign-in.button-blue {
  background: linear-gradient(to bottom, #63b8d8 0%, #367db7 100%);
}
.sign-in.button-blue:hover {
  color: white;
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}

@media screen and (max-width: 1600px) {
  .faq-info .faq-left {
    padding: 70px 30px 10px 15px;
  }
  .faq-info .faq-right p {
    margin-bottom: 35px;
  }
  .faq-info .faq-right form .write-us-inputs {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .faq-info .faq-right form .write-us-inputs input {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .faq-info {
    flex-wrap: wrap;
  }
  .faq-info .faq-left {
    width: 100%;
    padding: 30px 16.6666% 55px;
  }
  .faq-info .faq-right {
    padding: 50px 16.6666%;
  }
  .faq-info .faq-right {
    width: 100%;
  }
  .faq-info .faq-right p {
    width: 100%;
  }
  .faq-info .faq-right form .write-us-inputs input {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .faq-info .faq-left {
    padding: 20px 15px 30px;
  }
  .hint-title {
    margin: 0 0 30px;
    padding: 10px;
  }
  .faq-info .faq-right {
    padding: 45px 15px;
  }
  .faq-info .faq-right .write-us-inner {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .hint-title {
    margin-bottom: 15px;
  }
  .faq-info .faq-right form .write-us-inputs input {
    width: 100%;
  }
  .faq-info .faq-right form button {
    max-width: 100%;
  }
}
</style>
